import {SUBMIT_ECONTRACT} from '../actions/econtractActions';

// null is set as the default value here for state, because Redux will complain if state is undefined. 
// You can set initial state here, but it is recommended on the Redux documentation to preload the state within the redux store. 
// https://redux.js.org/recipes/structuring-reducers/initializing-state
export default function econtractReducer(state = null, action) {
  switch (action.type) {
    case SUBMIT_ECONTRACT:
      return action.payload.econtract;
    default:
      return state;
  }
}
