export const SUBMIT_TTNFT_CONTRACT = 'SUBMIT_TTNFT_CONTRACT';

// The function, user, is an 'action creator'
// The return value is an 'action'
export function submitTTNftContract(input) {
  return {
    type: SUBMIT_TTNFT_CONTRACT,
    payload: {
      ttnftContract: input
    }
  }
}
