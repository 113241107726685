export const SUBMIT_GCCONTRACT = 'SUBMIT_GCCONTRACT';

// The function, user, is an 'action creator'
// The return value is an 'action'
export function submitGCContract(input) {
  return {
    type: SUBMIT_GCCONTRACT,
    payload: {
      gccontract: input
    }
  }
}
