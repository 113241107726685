export const SUBMIT_ENFT_CONTRACT = 'SUBMIT_ENFT_CONTRACT';

// The function, user, is an 'action creator'
// The return value is an 'action'
export function submitENftContract(input) {
  return {
    type: SUBMIT_ENFT_CONTRACT,
    payload: {
      enftContract: input
    }
  }
}
