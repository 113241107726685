export const SUBMIT_LCONTRACT = 'SUBMIT_LCONTRACT';

// The function, user, is an 'action creator'
// The return value is an 'action'
export function submitLContract(input) {
  return {
    type: SUBMIT_LCONTRACT,
    payload: {
      lcontract: input
    }
  }
}
