export const SUBMIT_GCNFT_CONTRACT = 'SUBMIT_GCNFT_CONTRACT';

// The function, user, is an 'action creator'
// The return value is an 'action'
export function submitGCNftContract(input) {
  return {
    type: SUBMIT_GCNFT_CONTRACT,
    payload: {
      gcnftContract: input
    }
  }
}
