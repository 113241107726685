export const SUBMIT_DWNFT_CONTRACT = 'SUBMIT_DWNFT_CONTRACT';

// The function, user, is an 'action creator'
// The return value is an 'action'
export function submitDWNftContract(input) {
  return {
    type: SUBMIT_DWNFT_CONTRACT,
    payload: {
      dwnftContract: input
    }
  }
}
