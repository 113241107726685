import React, {useState} from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import Lgem from './Components/Lgem';
import LKgem from './Components/LKgem';
import Cgem from './Components/Cgem';
import Dgem from './Components/Dgem';
import Bgem from './Components/Bgem';
import Egem from './Components/Egem';
import GCgem from './Components/GCgem';
import TTgem from './Components/TTgem';
import DWgem from './Components/DWgem';
import Home from './Components/Home';
import Header from "./Components/Header";
import Cyborx from './Components/Cyborx';
import Lacey from './Components/Lacey';
import Lost from './Components/Lkm';
import DTM from './Components/Dtm';
import Bio from './Components/Bio';
import Eva from './Components/Eva';
import Comic from './Components/Comic';
import Tt from './Components/TT';
import Dw from './Components/DW';
import {connect, Provider} from 'react-redux';
import store from './Redux/store';
import Contact from "./Components/Contact";
import Footer from "./Components/Footer";
import {ToastContainer} from "react-toastify";
import {Spin} from "antd";
// import 'antd/dist/antd.css';

function App() {
  const [isLoading, setIsLoading] = useState(false);
  const override = `
  position: absolute;
  margin: auto;
  border-color: red;
  opacity: 0.7;
  z-index: 1;
`;
  return (
    <Provider store={store}>
      {/* eslint-disable-next-line react/jsx-no-undef */}
      <div className="main">
        {/*<Spin spinning={isLoading} size="large">*/}

          <div className="container">

            <Router>
              <Header/>
              <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="lgem" element={<Lgem setIsLoading={setIsLoading}/>}/>
                <Route path="lkgem" element={<LKgem setIsLoading={setIsLoading}/>}/>
                <Route path="cgem" element={<Cgem setIsLoading={setIsLoading}/>}/>
                <Route path="dgem" element={<Dgem setIsLoading={setIsLoading}/>}/>
                <Route path="bgem" element={<Bgem setIsLoading={setIsLoading}/>}/>
                <Route path="egem" element={<Egem setIsLoading={setIsLoading}/>}/>
                <Route path="gcgem" element={<GCgem setIsLoading={setIsLoading}/>}/>
                <Route path="ttgem" element={<TTgem setIsLoading={setIsLoading}/>}/>
                <Route path="dwgem" element={<DWgem setIsLoading={setIsLoading}/>}/>
                <Route path="Cyborx" element={<Cyborx/>}/>
                <Route path="lacey" element={<Lacey/>}/>
                <Route path="lost" element={<Lost/>}/>
                <Route path="dtm" element={<DTM/>}/>
                <Route path="bio" element={<Bio/>}/>
                <Route path="eva" element={<Eva/>}/>
                <Route path="comic" element={<Comic/>}/>
                <Route path="tt" element={<Tt/>}/>
                <Route path="dw" element={<Dw/>}/>
              </Routes>

              <Footer/>
             
              <ToastContainer autoClose={5000} hideProgressBar/>


            </Router>
          </div>
        {/*</Spin>*/}

      </div>

    </Provider>
  );
}

export default App;
