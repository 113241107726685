export const SUBMIT_BNFT_CONTRACT = 'SUBMIT_BNFT_CONTRACT';

// The function, user, is an 'action creator'
// The return value is an 'action'
export function submitBNftContract(input) {
  return {
    type: SUBMIT_BNFT_CONTRACT,
    payload: {
      bnftContract: input
    }
  }
}
