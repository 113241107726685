export const SUBMIT_BCONTRACT = 'SUBMIT_BCONTRACT';

// The function, user, is an 'action creator'
// The return value is an 'action'
export function submitBContract(input) {
  return {
    type: SUBMIT_BCONTRACT,
    payload: {
      bcontract: input
    }
  }
}
