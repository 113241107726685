import Onboard from 'bnc-onboard'

const ACTIVE_NETWORK_ID = 1
const INFURA_KEY = "a84a4e44da0346718f6e9f8db7e20c1e"
const APP_URL = "https://mandoxgem.farm/"
const RPC_URL = "https://mainnet.infura.io/v3/a84a4e44da0346718f6e9f8db7e20c1e"
const APP_NAME = "MandoxGem"

const wallets = [
  { walletName: "metamask" },
  {
    walletName: "walletConnect",
    infuraKey: INFURA_KEY,
    bridge: "https://derelay.rabby.io/"
  },
  { walletName: "coinbase" },
  {
    walletName: 'ledger',
    rpcUrl: RPC_URL
  }
]
export const onboard = Onboard({
  //... other options
  dappId: '72501210-f37e-47bf-89a0-8c282f971a5f',
  networkId: ACTIVE_NETWORK_ID,
  walletSelect: {
    wallets: wallets,
  },
});

export function initOnboard(subscriptions) {
  return Onboard({
    dappId: '72501210-f37e-47bf-89a0-8c282f971a5f',
    networkId: ACTIVE_NETWORK_ID,
    subscriptions,
    walletSelect: {
      wallets: wallets,
    },
    walletCheck: [
      {checkName: 'derivationPath'},
      {checkName: 'connect'},
      {checkName: 'accounts'},
      {checkName: 'network'},
    ],
  });
}
