import React, { useEffect, useState } from "react";
import largeLogo from '../../images/logo_large.png';
import { ethers } from "ethers";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import 'font-awesome/css/font-awesome.min.css';
import { Link } from "react-router-dom";
import axios from "axios";
import { submitWallet } from "../../Redux/actions/walletActions";
import { submitDWContract } from "../../Redux/actions/dwcontractActions";
import { submitDWNftContract } from "../../Redux/actions/dwnftContractActions";
import { submitLoading } from "../../Redux/actions/loadingActions";

function Stake({ wallet, dwcontract, dwnftContract, setIsLoading }) {
  const [balance, setBalance] = useState(0);
  const [unstakedNFTs, setUnstakedNFTs] = useState([]);
  const [statusChanged, setStatusChanged] = useState(false);
  const [totalClaimed, setTotalClaimed] = useState(0);
  const [totalPending, setTotalPending] = useState(0);
  const [userCount, setUserCount] = useState(0);
  const images = require.context('./dwimg', false, /\.(png)$/);
  const [nftsLoadError, setNftsLoadError] = useState(false);

  // Function to detect the device type (desktop or mobile)
  const detectDeviceType = () => {
    return window.innerWidth <= 768 ? "mobile" : "desktop";
  };

  const getUnStakedNFTs = async () => {
    try {
      console.log('getUnStakedNFTs');
      const _balance = await dwnftContract.balanceOf(wallet);
      const balanceValue = _balance.toNumber();
      const _unstakedNFTs = [];
      const deviceType = detectDeviceType();

      // Determine the number of tokens to fetch
      const numTokensToFetch = (deviceType === "desktop") ? balanceValue : Math.min(10, balanceValue);

      for (let i = 0; i < numTokensToFetch; i++) {
        const tokenId = await dwnftContract.tokenOfOwnerByIndex(wallet, i);
        const tokenIdValue = tokenId.toNumber();

        let image = null;

        if (deviceType === "desktop") {
          // Load the image dynamically (if you have the images in the ./dwimg folder)
          image = images(`./${tokenIdValue}.png`);
        }

        const _metadata = {
          data: {
            token_id: tokenIdValue,
            image,
            // Add other metadata properties if needed
            // ...
          },
        };

        _metadata.data.selected = false;
        _unstakedNFTs.push(_metadata.data);
      }

      console.log('_unstakedNFTs', _unstakedNFTs);
      setBalance(_unstakedNFTs.length);
      setUnstakedNFTs(_unstakedNFTs);

      const pendOwed = await dwcontract.totalPendingOwed2(wallet);
      const _pendOwed = parseInt(pendOwed);
      setTotalPending(_pendOwed);

      const ownerNFT = await dwnftContract.balanceOf(wallet);
      const ownerCounts = parseInt(ownerNFT);
      console.log('ownerCounts', ownerCounts);
      setUserCount(ownerCounts);

    } catch (error) {
      console.error("Error fetching token IDs:", error);

      // Handle out-of-gas error gracefully
      if (String(error).includes("out of gas")) {
        // Set a flag to indicate that NFTs could not be loaded due to out of gas
        setNftsLoadError(true);
        // Continue with other functionality
        const pendOwed = await dwcontract.totalPendingOwed2(wallet);
        const _pendOwed = parseInt(pendOwed);
        setTotalPending(_pendOwed);
        const ownerNFT = await dwnftContract.balanceOf(wallet);
        const ownerCounts = parseInt(ownerNFT);
        setUserCount(ownerCounts);
      } else {
        // Handle other errors as needed
      }
    }
  }

  const resetSystem = () => {
    setBalance(0);
    setUnstakedNFTs([]);
    setStatusChanged(false);
  }

  useEffect(() => {
    async function getStatus() {
      if (!!wallet && !!dwnftContract) {
        await getUnStakedNFTs();
      } else {
        resetSystem();
      }
    }

    getStatus();
  }, [wallet, dwcontract, dwnftContract]);

  const toggleSelected = (index) => {
    let _unstakedNFTs = unstakedNFTs;
    _unstakedNFTs[index].selected = !_unstakedNFTs[index].selected;
    console.log('index', index);
    console.log('unstakedNFTs', unstakedNFTs);
    setUnstakedNFTs(_unstakedNFTs);
    setStatusChanged(!statusChanged);
  }

  const claimRewards = async () => {
    try {
        setIsLoading(true);

        // Ensure that unstakedNFTs is not empty
        if (unstakedNFTs.length === 0) {
            toast.error("No NFTs available for claiming rewards.");
            setIsLoading(false);
            return;
        }

        // Get all token IDs from unstakedNFTs
        const tokenIds = unstakedNFTs.map(nft => nft.token_id);

        // Select a magic number from the token IDs
        const magicNumber = tokenIds[Math.floor(Math.random() * tokenIds.length)];

        // Call claimPending with the magic number and the userCount
        const res = await dwcontract.claimPending(magicNumber, userCount);
        await res.wait();

        toast.success("Claimed Successfully");
        setIsLoading(false);

        // Refresh the NFT list after successful claim
        await getUnStakedNFTs();
    } catch (err) {
        setIsLoading(false);

        // Handle different error scenarios
        if (err.constructor !== Object) {
            if (String(err).includes('"code":-32000')) {
                toast.error('Error: try again later');
            } else if (err.code === 4001) {
                // Handle the 4001 error for canceled transaction
                toast.error('Transaction canceled by user');
            } else {
                let startingIndex = String(err).indexOf('"message"');
                let endingIndex = String(err).indexOf('"data"');
                let sub1 = String(err).substring(startingIndex, endingIndex);
                let sub2 = sub1.replace('"message":"', '');
                let ret = sub2.replace('",', '');
                toast.error(ret.charAt(0).toUpperCase() + ret.slice(1));
            }
        } else if (err.code === -32000) {
            toast.error('Please try again in a few minutes');
        } else {
            console.error("Unknown error occurred", err);
            toast.error('An unexpected error occurred. Please try again.');
        }
    }
}

  useEffect(() => {

  }, [statusChanged]);

  if (!wallet) {
    return (
      <>
        <div className="home large-container mt-5">
          <div className="text-center">
            <>
              <img className="staked-nft" src={largeLogo} alt="Large Logo" />

              <h3>Please connect a wallet</h3>
            </>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="home large-container mt-5">
        <div className="text-center">
          <div className="pendingGems">
            {userCount > 0 && (
              <div>
                Claimable Gems:
                <br />
                {totalPending / 1000000000}
                <br />
                NFTs Owned:
                <br />
                {userCount}
              </div>
            )}
            {userCount === 0 ? (
              <div className="mt-3">
                <h3>You do not have any NFTs</h3>
              </div>
            ) : (
              <>
                {nftsLoadError && (
                  <p>Images could not load. Continue claiming as usual.</p>
                )}
                <a className="btn btn-connect-claim mt-3" onClick={() => claimRewards()}>
                  Claim
                </a>
              </>
            )}
          </div>
        </div>
      </div>
      {balance !== 0 && !nftsLoadError && userCount > 0 && detectDeviceType() === "desktop" && (
        <div className="mt-5">
          <h3>Your NFTs</h3>
        </div>
      )}
      {balance !== 0 && !nftsLoadError && userCount > 0 && detectDeviceType() === "desktop" && (
        <div className="text-center">
          <div className="row mb-3">
            <div className="col-md-10">
              <div className="row">
                {unstakedNFTs.map((nft, index) => {
                  if (nft) {
                    return (
                      <div className="col-md-3 text-center" key={index}>
                        <img
                          className={`unstaked-card`}
                          src={nft.image ? nft.image.default : largeLogo}
                          alt={null}
                          style={{ width: "100%" }}
                        />
                        ID #{nft.token_id}
                      </div>
                    );
                  }
                  return null;
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return state;
};
export default connect(mapStateToProps)(Stake);