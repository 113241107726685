import {createStore, combineReducers} from 'redux';
import contractReducer from "./reducers/contractReducers";
import lcontractReducer from "./reducers/lcontractReducers";
import lkcontractReducer from "./reducers/lkcontractReducers";
import dcontractReducer from "./reducers/dcontractReducers";
import bcontractReducer from "./reducers/bcontractReducers";
import econtractReducer from "./reducers/econtractReducers";
import gccontractReducer from "./reducers/gccontractReducers";
import dwcontractReducer from "./reducers/dwcontractReducers";
import walletReducer from "./reducers/walletReducers";
import nftContractReducer from "./reducers/nftContractReducers";
import lnftContractReducer from "./reducers/lnftContractReducers";
import lknftContractReducer from "./reducers/lknftContractReducers";
import dnftContractReducer from "./reducers/dnftContractReducers";
import bnftContractReducer from "./reducers/bnftContractReducers";
import enftContractReducer from "./reducers/enftContractReducers";
import gcnftContractReducer from "./reducers/gcnftContractReducers";
import ttnftContractReducer from "./reducers/ttnftContractReducers";
import ttcontractReducer from "./reducers/ttcontractReducers";
import dwnftContractReducer from "./reducers/dwnftContractReducers";
import loadingReducer from "./reducers/loadingReducers";

// combineReducers takes in multiple reducers, and returns a single reducer to be used in the createStore(...) function.
// combineReducers takes in multiple 'key : value' pairs to combine the reducers.
// The key is the name of variable within state (in the redux store), and the value is the reducer used to change that variable.
const allReducers = combineReducers({
  wallet: walletReducer,
  contract: contractReducer,
  nftContract: nftContractReducer,
  lcontract: lcontractReducer,
  lnftContract: lnftContractReducer,
  lkcontract: lkcontractReducer,
  lknftContract: lknftContractReducer,
  dcontract: dcontractReducer,
  dnftContract: dnftContractReducer,
  bcontract: bcontractReducer,
  bnftContract: bnftContractReducer,
  econtract: econtractReducer,
  enftContract: enftContractReducer,
  gccontract: gccontractReducer,
  gcnftContract: gcnftContractReducer,
  ttcontract: ttcontractReducer,
  ttnftContract: ttnftContractReducer,
  dwcontract: dwcontractReducer,
  dwnftContract: dwnftContractReducer,
  loading: loadingReducer
});

// createStore takes in 3 parameters: 1. Reducer 2. preloadedState 3. Enhancer.
// In this case: 
// 1. Is the allReducer defined above
// 2. Are the intial values of the state within the redux store
// 3. Allows the store to be viewed within the Redux Tools Extension, a recommended tool when working with Redux.
// https://redux.js.org/api/createstore
const store = createStore(
  allReducers,
  {
    wallet: "",
    contract: {},
    nftContract: {},
    lcontract: {},
    lnftContract: {},
    lkcontract: {},
    lknftContract: {},
    dcontract: {},
    dnftContract: {},
    bcontract: {},
    bnftContract: {},
    econtract: {},
    enftContract: {},
    gccontract: {},
    gcnftContract: {},
    ttcontract: {},
    ttnftContract: {},
    dwcontract: {},
    dwnftContract: {},
    loading: false
  },
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
