import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import Web3 from "web3";
import laceyMgem from '../../contracts/laceyMgem.json';
import lace from '../../images/lace.png';
import im from "../Contact/discord.png";
import tg from "../Contact/telegram.png";

function Home({wallet, lcontract}) {
  const [stakedCount, setStakedCount] = useState(0);
  const [totalClaimed, setTotalClaimed] = useState(0);
  const [totalPending, setTotalPending] = useState(0);



  useEffect(() => {
    async function getStatus() {
      const provider = new Web3.providers.HttpProvider('https://mainnet.infura.io/v3/a84a4e44da0346718f6e9f8db7e20c1e');
      const web3 = new Web3(provider);
      let _lcontract = new web3.eth.Contract(laceyMgem.abi, laceyMgem.networks[1].address);
      let _totalClaimed = await _lcontract.methods.totalEarned().call()/1000000000;
      setTotalClaimed(_totalClaimed);



    }
    getStatus();

  }, []);

  const startStaking = async() => {

  }
  return (
    <>
      <div className="lgo" style={{ marginTop: 200 }}>
            <img src={lace}/>
      </div>
      <div className="row">
        <div className="offset-md-1 col-md-4 stake-bar">
          <h4>$mGem Rewards</h4>
          <div className="pl-2 mt-3 pb-3 pb-line">30 days Per NFT: 30000 </div>
          <div className="pl-2 mt-3 pb-3 pb-line">1 Day Per NFT: 1000</div>
          <div className="pl-2 pt-3">1st Claim Bonus Per NFT: 10000</div>
        </div>
        <div className="offset-md-2 col-md-4 stake-bar">
          <h4>  Lacedameon</h4>
          <div className="pl-2 pt-3 pb-3 pb-line">Total Collection NFTs: 1000</div>
          <div className="pl-2 pt-3 pb-3 ">Total Rewards Claimed: {totalClaimed} </div>
          <div className="pl-2 pt-3"></div>
        </div>
      </div>
      <div className="row">
        <div className="mt-5 mx-auto">
          <Link to='/lgem' className="btn btn-connect-enter">Enter</Link>
        </div>
      </div>
      <div>

      </div>



      <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-12 col-10 mt-5">
          <div className="run2">
            <div className="collect-container2 ct-main mr-5 ml-5 mb-5">
              <div className="container ">
                <div className="row justify-content-md-between justify-content-center ">
                  <div className="col-md-4 col-8">
                    <div className='head2'>
                      <div className='text-md-start text-center'>
                        <h6>Need some help?</h6>
                        <h6 className='mt-5'>Ask Questions on Discord or Telegram </h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-8 text-md-end text-center mt-md-0 mt-5">
                    <div className='head2'>
                      <p className='font-weight-bold'>Contact Us</p>
                      <a href="https://discord.com/invite/ZJwcx9nKNK" target="_blank"><img src={im} alt="" className='me-md-3 mt-2 ml-2 mr-2 img-fluid set' /></a>
                      <a href="https://t.me/officialmandox" target="_blank"><img src={tg} alt="" className='me-md-3 mt-2 ml-2 mr-2 img-fluid set' /></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>




    </>
  );
}

const mapStateToProps = state => {
  return state;
}

export default connect(mapStateToProps)(Home);
