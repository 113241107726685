export const SUBMIT_TTCONTRACT = 'SUBMIT_TTCONTRACT';

// The function, user, is an 'action creator'
// The return value is an 'action'
export function submitTTContract(input) {
  return {
    type: SUBMIT_TTCONTRACT,
    payload: {
      ttcontract: input
    }
  }
}
