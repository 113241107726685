export const SUBMIT_LKNFT_CONTRACT = 'SUBMIT_LKNFT_CONTRACT';

// The function, user, is an 'action creator'
// The return value is an 'action'
export function submitLKNftContract(input) {
  return {
    type: SUBMIT_LKNFT_CONTRACT,
    payload: {
      lknftContract: input
    }
  }
}
