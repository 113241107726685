export const SUBMIT_LKCONTRACT = 'SUBMIT_LKCONTRACT';

// The function, user, is an 'action creator'
// The return value is an 'action'
export function submitLKContract(input) {
  return {
    type: SUBMIT_LKCONTRACT,
    payload: {
      lkcontract: input
    }
  }
}
