export const SUBMIT_ECONTRACT = 'SUBMIT_ECONTRACT';

// The function, user, is an 'action creator'
// The return value is an 'action'
export function submitEContract(input) {
  return {
    type: SUBMIT_ECONTRACT,
    payload: {
      econtract: input
    }
  }
}
