import {SUBMIT_GCCONTRACT} from '../actions/gccontractActions';

// null is set as the default value here for state, because Redux will complain if state is undefined. 
// You can set initial state here, but it is recommended on the Redux documentation to preload the state within the redux store. 
// https://redux.js.org/recipes/structuring-reducers/initializing-state
export default function gccontractReducer(state = null, action) {
  switch (action.type) {
    case SUBMIT_GCCONTRACT:
      return action.payload.gccontract;
    default:
      return state;
  }
}
