export const SUBMIT_DNFT_CONTRACT = 'SUBMIT_DNFT_CONTRACT';

// The function, user, is an 'action creator'
// The return value is an 'action'
export function submitDNftContract(input) {
  return {
    type: SUBMIT_DNFT_CONTRACT,
    payload: {
      dnftContract: input
    }
  }
}
