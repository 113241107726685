import React, { useState, useEffect } from "react";
import {Link} from "react-router-dom";
import logoImg from "../../images/logo_G.png";
import { initOnboard } from "../../hook/walletService"
import {ethers} from "ethers";
import { connect } from 'react-redux';
import cyborxMgem from '../../contracts/cyborxMgem.json';
import Cyborx from '../../contracts/TheCyborx.json';
import laceyMgem from '../../contracts/laceyMgem.json';
import Lacedameon from '../../contracts/Lacedameon.json';
import dMgem from '../../contracts/dtmMgem.json';
import dtm from '../../contracts/Dtm.json';
import bMgem from '../../contracts/bioMgem.json';
import bio from '../../contracts/BioApes.json';
import lostMgem from '../../contracts/lostMgem.json';
import lost from '../../contracts/Lost.json';
import eMgem from '../../contracts/evaMgem.json';
import eva from '../../contracts/Eva.json'; 
import dwMgem from '../../contracts/dwMgem.json';
import dw from '../../contracts/dw.json'; 
import GCMgem from '../../contracts/GCMgem.json';
import comic from '../../contracts/comic.json'; 
import ttMgem from '../../contracts/ttMgem.json';
import Tater from '../../contracts/TT.json'; 
import {submitWallet} from "../../Redux/actions/walletActions";
import {submitContract} from "../../Redux/actions/contractActions";
import {submitNftContract} from "../../Redux/actions/nftContractActions";
import {submitLContract} from "../../Redux/actions/lcontractActions";
import {submitLNftContract} from "../../Redux/actions/lnftContractActions";
import {submitLKContract} from "../../Redux/actions/lkcontractActions";
import {submitLKNftContract} from "../../Redux/actions/lknftContractActions";
import {submitDContract} from "../../Redux/actions/dcontractActions";
import {submitDNftContract} from "../../Redux/actions/dnftContractActions";
import {submitBContract} from "../../Redux/actions/bcontractActions";
import {submitBNftContract} from "../../Redux/actions/bnftContractActions";
import {submitEContract} from "../../Redux/actions/econtractActions";
import {submitENftContract} from "../../Redux/actions/enftContractActions";
import {submitGCContract} from "../../Redux/actions/gccontractActions";
import {submitGCNftContract} from "../../Redux/actions/gcnftContractActions";
import {submitTTContract} from "../../Redux/actions/ttcontractActions";
import {submitTTNftContract} from "../../Redux/actions/ttnftContractActions";
import {submitDWContract} from "../../Redux/actions/dwcontractActions";
import {submitDWNftContract} from "../../Redux/actions/dwnftContractActions";

function Header({onSubmitWallet, onSubmitContract, onSubmitNftContract, onSubmitLContract, onSubmitLNftContract, onSubmitLKContract, onSubmitLKNftContract, onSubmitDContract, onSubmitDNftContract, onSubmitBContract, onSubmitBNftContract, onSubmitEContract, onSubmitENftContract, onSubmitGCContract, onSubmitGCNftContract, onSubmitTTContract, onSubmitTTNftContract, onSubmitDWContract, onSubmitDWNftContract}) {

  const [onboard, setOnboard] = useState();
  const [walletAddr, setWalletAddr] = useState(null);
  const [walletConnected, setWalletConnected] = useState(false);
  useEffect(() => {
    const _onboard = initOnboard({
      address: (address) => {
        console.log('address callback: ', address);
        if (!!address) {
          onSubmitWallet(address);
          setWalletAddr(
            shortenHex(address)
          );
        } else {
          onSubmitWallet(null);
          setWalletConnected(false);
        }
      },
      network: (network) => {
        console.log('network callback: ', network)
      },
      balance: (balance) => {
        console.log('balance', balance);
      },
      wallet: async (wallet) => {
        console.log('wallet', wallet);
        if (wallet.provider) {
          let ethersProvider = new ethers.providers.Web3Provider(wallet.provider);
          let _contract = new ethers.Contract(cyborxMgem.networks[1].address, cyborxMgem.abi, ethersProvider.getUncheckedSigner());
          let _nftContract = new ethers.Contract(Cyborx.networks[1].address, Cyborx.abi, ethersProvider.getUncheckedSigner());
          let _lcontract = new ethers.Contract(laceyMgem.networks[1].address, laceyMgem.abi, ethersProvider.getUncheckedSigner());
          let _lnftContract = new ethers.Contract(Lacedameon.networks[1].address, Lacedameon.abi, ethersProvider.getUncheckedSigner());
          let _lkcontract = new ethers.Contract(lostMgem.networks[1].address, lostMgem.abi, ethersProvider.getUncheckedSigner());
          let _lknftContract = new ethers.Contract(lost.networks[1].address, lost.abi, ethersProvider.getUncheckedSigner());
          let _dcontract = new ethers.Contract(dMgem.networks[1].address, dMgem.abi, ethersProvider.getUncheckedSigner());
          let _dnftContract = new ethers.Contract(dtm.networks[1].address, dtm.abi, ethersProvider.getUncheckedSigner());
          let _bcontract = new ethers.Contract(bMgem.networks[1].address, bMgem.abi, ethersProvider.getUncheckedSigner());
          let _bnftContract = new ethers.Contract(bio.networks[1].address, bio.abi, ethersProvider.getUncheckedSigner());
          let _econtract = new ethers.Contract(eMgem.networks[1].address, eMgem.abi, ethersProvider.getUncheckedSigner());
          let _enftContract = new ethers.Contract(eva.networks[1].address, eva.abi, ethersProvider.getUncheckedSigner());
          let _gccontract = new ethers.Contract(GCMgem.networks[1].address, GCMgem.abi, ethersProvider.getUncheckedSigner());
          let _gcnftContract = new ethers.Contract(comic.networks[1].address, comic.abi, ethersProvider.getUncheckedSigner());
          let _ttcontract = new ethers.Contract(ttMgem.networks[1].address, ttMgem.abi, ethersProvider.getUncheckedSigner());
          let _ttnftContract = new ethers.Contract(Tater.networks[1].address, Tater.abi, ethersProvider.getUncheckedSigner());
          let _dwcontract = new ethers.Contract(dwMgem.networks[1].address, dwMgem.abi, ethersProvider.getUncheckedSigner());
          let _dwnftContract = new ethers.Contract(dw.networks[1].address, dw.abi, ethersProvider.getUncheckedSigner());
          onSubmitContract(_contract);
          onSubmitNftContract(_nftContract);
          onSubmitLContract(_lcontract);
          onSubmitLNftContract(_lnftContract);
          onSubmitLKContract(_lkcontract);
          onSubmitLKNftContract(_lknftContract);
          onSubmitDContract(_dcontract);
          onSubmitDNftContract(_dnftContract);
          onSubmitBContract(_bcontract);
          onSubmitBNftContract(_bnftContract);
          onSubmitEContract(_econtract);
          onSubmitENftContract(_enftContract);
          onSubmitGCContract(_gccontract);
          onSubmitGCNftContract(_gcnftContract);
          onSubmitTTContract(_ttcontract);
          onSubmitTTNftContract(_ttnftContract);
          onSubmitDWContract(_dwcontract);
          onSubmitDWNftContract(_dwnftContract);
        } else {
          onSubmitNftContract({});
          onSubmitContract({});
          onSubmitLNftContract({});
          onSubmitLContract({});
          onSubmitLKNftContract({});
          onSubmitLKContract({});
          onSubmitDNftContract({});
          onSubmitDContract({});
          onSubmitBNftContract({});
          onSubmitBContract({});
          onSubmitENftContract({});
          onSubmitEContract({});
          onSubmitGCNftContract({});
          onSubmitGCContract({});
          onSubmitTTNftContract({});
          onSubmitTTContract({});
          onSubmitDWNftContract({});
          onSubmitDWContract({});
        }
      }
    })

    setOnboard(_onboard)
  }, []);

  const shortenHex = (hex, length = 4) => {
    return `${hex.substring(0, length + 2)}…${hex.substring(
      hex.length - length
    )}`;
  }

  const connectWallet = async () => {
    console.log('connectWallet');
    if (onboard) {
      const walletSelected = await onboard.walletSelect()
      if (!walletSelected) return

      console.log('wallet selected: ', walletSelected)
      const readyToTransact = await onboard.walletCheck()
      console.log('Wallet selected: ', walletSelected, ' Ready to transact: ', readyToTransact)
      if (walletSelected && readyToTransact) {
        setWalletConnected(true);
      }
    }
  }

  const disconnectWallet = async () => {
    if (onboard) {
      onboard.walletReset();
    }
  }
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light" >
        <Link className="navbar-brand ml-5" to="/"><img src={logoImg} /></Link>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item  ml-5">
              <Link to="/"><h5>HOME</h5></Link>
              </li>
            <li className="nav-item ml-5">
            <a href="https://swap.mandoxgem.com/"><h5>SWAP</h5></a>
            </li>

            <li className="nav-item ml-5">
            <a href="https://mandox.farm/"><h5>FARM</h5></a>
            </li>
            <li className="nav-item ml-5">
            <a href="https://mandoxglobal.com/"><h5>MANDOX</h5></a>
            </li>
            <li></li>
          </ul>
        </div>
        <span className="navbar-text">
          {
            !walletConnected && (
              <a className="btn btn-connect mr-5" onClick={() => connectWallet()}>CONNECT WALLET</a>
            )
          }
          {
            walletConnected && (
              <a className="btn btn-connect mr-5" onClick={() => disconnectWallet()}>DISCONNECT WALLET</a>
            )
          }
        </span>
      </nav>
    </>
  );
}

const mapStateToProps = (state, props) => {
  return {
    user: state.user,
    contract: state.contract
  };
};


const mapActionsToProps = {
  onSubmitWallet: submitWallet,
  onSubmitContract: submitContract,
  onSubmitNftContract: submitNftContract,
  onSubmitLContract: submitLContract,
  onSubmitLNftContract: submitLNftContract,
  onSubmitLKContract: submitLKContract,
  onSubmitLKNftContract: submitLKNftContract,
  onSubmitDContract: submitDContract,
  onSubmitDNftContract: submitDNftContract,
  onSubmitBContract: submitBContract,
  onSubmitBNftContract: submitBNftContract,
  onSubmitEContract: submitEContract,
  onSubmitENftContract: submitENftContract,
  onSubmitGCContract: submitGCContract,
  onSubmitGCNftContract: submitGCNftContract,
  onSubmitTTContract: submitTTContract,
  onSubmitTTNftContract: submitTTNftContract,
  onSubmitDWContract: submitDWContract,
  onSubmitDWNftContract: submitDWNftContract
}
export default connect(mapStateToProps, mapActionsToProps)(Header);

