export const SUBMIT_DCONTRACT = 'SUBMIT_DCONTRACT';

// The function, user, is an 'action creator'
// The return value is an 'action'
export function submitDContract(input) {
  return {
    type: SUBMIT_DCONTRACT,
    payload: {
      dcontract: input
    }
  }
}
