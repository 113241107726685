import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import Web3 from "web3";

import gem from '../../images/GEM.png';


function Home({wallet}) {

  const [showDisclaimer, setShowDisclaimer] = useState(false); // State for toggle

  const toggleDisclaimer = () => {
    setShowDisclaimer(!showDisclaimer);
  }



  const startStaking = async() => {

  }
  return (
    <>
      <div className="lgo" style={{ marginTop: 200 }}>
            <img src={gem}/>
      </div>

            {/* Title above the links */}
            <div className="title">
        <h2>Gem Collections</h2>
      </div>
      
      <div className="row">

      <div className=" collinks mt-5 mx-auto">
          <Link to='/lacey' className="btn btn-connect-enter">LACEDAMEON</Link>
        </div>

      <div className=" collinks mt-5 mx-auto">
          <Link to='/cyborx' className="btn btn-connect-enter">CYBORX</Link>
        </div>

        <div className=" collinks mt-5 mx-auto">
        <Link to='/bio' className="btn btn-connect-enter">BIO APES</Link>
          
        </div>

        <div className="collinks mt-5 mx-auto">
          <Link to='/lost' className="btn btn-connect-enter">LOST KIDS</Link>
        </div>

        <div className="collinks mt-5 mx-auto">
        <Link to='/eva' className="btn btn-connect-enter">EVA</Link>
        </div>

        <div className="collinks mt-5 mx-auto">
          
          <Link to='/dtm' className="btn btn-connect-enter">DTM</Link>
        </div>

        <div className=" collinks mt-5 mx-auto">
          <Link to='/dw' className="btn btn-connect-enter">WEAVERS</Link>
        </div>

        <div className=" collinks mt-5 mx-auto">
          <Link to='/comic' className="btn btn-connect-enter">COMIC</Link>
        </div>

        <div className=" collinks mt-5 mx-auto">
          <Link to='/tt' className="btn btn-connect-enter">TATER TOWN</Link>
        </div>

        


        
      </div>
      <br/>
      
      <div className="pb-line"></div><br/>
      <div className="title">
        <h2>Mandox-Gem</h2>
      </div>
      <div className=" collinks2 mt-5 mx-auto">
    <a href="https://swap.mandoxgem.com" className="btn btn-connect-enter">SWAP</a>
</div>
       
        <br/><br/>

      {/* Disclaimer */}
      <div className="disclaimer-container" style={{ marginTop: 30, textAlign: 'center' }}>
        <button onClick={toggleDisclaimer} style={{ color: 'white', background: 'none', border: 'single', borderColor: 'white', borderRadius:'5px', cursor: 'pointer' }}>
          <strong>Click here for Disclaimer and Info</strong>
        </button>
        {showDisclaimer && (
          <div className="disclaimer-content" style={{ marginTop: 10, backgroundColor: 'transparent', padding: 10 }}>
            $mGEM Contract Address: 0x53b2F6BEBaA82aCAD31afdd93b1236C3F4F2F310
            <br/><br/>
            To Start Earning make your first claim with each collection you own. You will recieve 10000
            GEM per NFT for most collections you own and this will initialize your wallet to start the 1000 GEM per day earning. Some Are More, some are less for bonus amounts. <br/><br/>
            Bonus Reward as well as Daily Reward is subject to change at anytime. If a contract is upgraded at anypoint, system improvements are implemented you may lose your pending rewards. Max claim time is 
            once per 24 hours. There is no Claim Periods, Claim whenever you like. This system does not require any Approvals, any moving of NFTs/Assets. $mGEM claims are newly minted to the blockchain.
            Mandox its team, and community is not responsible for any lost or stolen assets due to wrong links clicked as this will be the only link for this. 
            We will never require anything else of investors and holders.
              <br/><br/>
            DTM= Discover the Mandox Collection containing The Fallen, The Lycans. The others are named accordingly to their individual collection/tribe name.
            <br/><br/>
            You may click the GEM logo at the top of the screen at anytime to return to the home page. If you want to see your NFTs on page you must use a PC (Ledger, MetaMask, WalletConnect) 
            otherwise no images will be shown to make sure the dApp as well as mGEM systems work smoothly, and efficiently across all devices and wallets. 

          </div>
        )}
      </div>
    </>
  );
}

const mapStateToProps = state => {
  return state;
}

export default connect(mapStateToProps)(Home);
